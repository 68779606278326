/*
   Polyfill for replaceChildren: https://caniuse.com/?search=replaceChildren

   As of 2024-04-15, we've had ≈500 unique users last season that are
   using Safari on MacOS 10.15 or older, which doesn't support
   replaceChildren.

   Not supported by corejs, so we're on our own: https://github.com/zloirock/core-js/issues/940
 */
(function() {
  function replaceChildren(...new_children) {
    const { childNodes } = this;
    while (childNodes.length) {
      childNodes[0].remove();
    }
    this.append(...new_children);
  }

  Document.prototype.replaceChildren ||= replaceChildren;
  DocumentFragment.prototype.replaceChildren ||= replaceChildren;
  Element.prototype.replaceChildren ||= replaceChildren;
})()
